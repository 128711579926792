@font-face {
  font-family: 'EnglishApp';
  src:  url('../fonts/EnglishApp.eot');
  src:  url('../fonts/EnglishApp.eot') format('embedded-opentype'),
    url('../fonts/EnglishApp.ttf') format('truetype'),
    url('../fonts/EnglishApp.woff') format('woff'),
    url('../fonts/EnglishApp.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i.ny {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'EnglishApp' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-user-fill:before {
  content: "\e91a";
}
.icon-padlock:before {
  content: "\e91b";
}
.icon-book-add:before {
  content: "\e919";
 
}
.icon-list:before {
  content: "\e914";
  
}
.icon-list-search:before {
  content: "\e915";
 
}
.icon-next-arrow:before {
  content: "\e916";
  color: #838181;
}
.icon-miccro:before {
  content: "\e917";
 
}
.icon-speaker:before {
  content: "\e918";
  color: #838181;
}
.icon-dot:before {
  content: "\e912";
  color: #838181;
}
.icon-close:before {
  content: "\e913";
  color: #838181;
}
.icon-share-icon:before {
  content: "\e900";
  color: #ff8304;
}
.icon-copy-icon:before {
  content: "\e901";
}
.icon-pen-edit-icon:before {
  content: "\e902";
}
.icon-more-dot-icon:before {
  content: "\e903";
  color: #838181;
}
.icon-hand-get-icon:before {
  content: "\e904";
}
.icon-heart-icon:before {
  content: "\e905";
}
.icon-MagnifyingGlass-icon:before {
  content: "\e906";
}
.icon-back-caret-icon:before {
  content: "\e907";
}
.icon-info-icon:before {
  content: "\e908";
  color: #666;
}
.icon-small-next-icon:before {
  content: "\e909";
}
.icon-back-icon:before {
  content: "\e90a";
}
.icon-logoff-icon:before {
  content: "\e90b";
}
.icon-gift-icon:before {
  content: "\e90c";
}
.icon-next-icon:before {
  content: "\e90d";
  color: #838181;
}
.icon-user-icon:before {
  content: "\e90e";
  color: #838181;
}
.icon-speaker-icon:before {
  content: "\e90f";
  color: #838181;
}
.icon-magnifying-glass-icon:before {
  content: "\e910";
  color: #838181;
}
.icon-mic-icon:before {
  content: "\e911";
  color: #838181;
}
