@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
    url(../fonts/MaterialIcons-Regular.woff) format('woff'),
    url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

@font-face {
  font-family: 'Framework7 Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Framework7Icons-Regular.woff2') format('woff2'),
    url('../fonts/Framework7Icons-Regular.woff') format('woff'),
    url('../fonts/Framework7Icons-Regular.ttf') format('truetype');
}

.f7-icons {
  font-family: 'Framework7 Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 28px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  text-align: center;
}
.demo-list-icon,
.icon-f7 {
  background: #ccc;
  display: block;
  position: relative;
}
.dark .demo-list-icon {
  background-color: #555;
}
.icon-f7 {
  background: url(../img/f7-icon.png) no-repeat center;
  background-size: cover;
}
.icon-vi {
  background: url(../img/vi-icon.png) no-repeat center;
  background-size: cover;
}

.ios .demo-list-icon,
.ios .icon-f7,
.ios .icon-vi {
  width: 28px;
  height: 28px;
  border-radius: 6px;
  box-sizing: border-box;
}
.md .demo-list-icon,
.md .icon-f7,
.md .icon-vi {
  width: 24px;
  height: 24px;
  border-radius: 7px;
}

/* FAB Morph */
.demo-fab-sheet {
  position: absolute;
  left: 16px;
  bottom: 16px;
  width: 140px;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  z-index: 1600;
  margin: 0 !important;
}

.demo-fab-fullscreen-sheet {
  position: absolute;
  left: 10px;
  right: 10px;
  top: calc(64px + var(--f7-safe-area-top));
  bottom: 0;
  background: #fff;
  z-index: 1600;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
.ios .demo-fab-sheet,
.ios .demo-fab-fullscreen-sheet {
  background: var(--f7-page-bg-color);
}
.md .demo-fab-sheet,
.md .demo-fab-fullscreen-sheet {
  background: var(--f7-popover-bg-color);
}
@media (min-width: 768px) {
  .demo-fab-fullscreen-sheet {
    left: 20%;
    width: 60%;
    right: auto;
    height: 80%;
    top: auto;
  }
}

/* Demo Messagebar */
.ios
  .messagebar:not(.messagebar-with-value):not(.messagebar-attachments-visible)
  a.demo-send-message-link {
  pointer-events: none;
  color: #8e8e8e;
}
.md
  .messagebar:not(.messagebar-with-value):not(.messagebar-attachments-visible)
  a.demo-send-message-link {
  pointer-events: none;
  opacity: 0.5;
}
/* Grid demo */
.grid-demo .grid > div {
  background: #fff;
  text-align: center;
  color: #000;
  border: 1px solid #ddd;
  padding: 5px;
  font-size: 12px;
}
.grid-demo .grid {
  margin-bottom: 16px;
}
.dark .grid-demo .grid > div {
  background-color: #000;
  color: #fff;
  border-color: #444;
}
/* Cards Demo */
.demo-card-header-pic .card-header {
  height: 40vw;
  background-size: cover;
  background-position: center;
  color: #fff;
}
.md .demo-card-header-pic .card-header {
  margin: 0 16px 16px;
  border-radius: 16px;
  position: relative;
  top: 16px;
}
.demo-card-header-pic .card-content-padding .date {
  color: #8e8e93;
}

/* Panels Demo */
.panel {
  min-width: 150px;
  max-width: 90%;
}
.ios .panel-right.panel-in ~ .view-main:after,
.ios .panel-right.panel-out ~ .view-main:after {
  content: '';
  height: 100%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 0;
  background: #ddd;
  z-index: 1000;
}
.ios .dark .panel-right.panel-in ~ .view-main:after,
.ios .dark .panel-right.panel-out ~ .view-main:after {
  background: #282828;
}

.ios .panel-left:after {
  content: '';
  height: 100%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 0;
  background: #ddd;
  z-index: 1000;
}
.ios .dark .panel-left:after {
  background: #282828;
}

/* Swipers Demo */
.demo-swiper {
  width: 100%;
  height: 100%;
}
.demo-swiper swiper-slide,
.demo-swiper-multiple swiper-slide,
.demo-swiper::part(slide),
.demo-swiper-multiple::part(slide) {
  font-size: 25px;
  font-weight: 300;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: #fff;
  color: #000;
}
.demo-swiper-multiple swiper-slide {
  box-sizing: border-box;
  border: 1px solid #ccc;
  background: #fff;
}
.demo-swiper-multiple {
  margin: 0px 0 35px;
  font-size: 18px;
  height: 120px;
}
.demo-swiper-multiple.demo-swiper-multiple-auto swiper-slide {
  width: 85%;
}
.demo-swiper-multiple.demo-swiper-multiple-auto swiper-slide:nth-child(2n) {
  width: 70%;
}
.demo-swiper-multiple.demo-swiper-multiple-auto swiper-slide:nth-child(3n) {
  width: 30%;
}
.demo-swiper-custom {
  height: 100%;
}
.demo-swiper-custom .swiper,
.demo-swiper-custom swiper-container {
  background: #000;
  height: 100%;
}
.demo-swiper-custom swiper-slide {
  background-size: cover;
  background-position: center;
}
.demo-swiper-custom .swiper-pagination .swiper-pagination-bullet {
  cursor: pointer;
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0);
  opacity: 1;
  border-radius: 0;
  transition: 200ms;
  position: relative;
  transform: scale(0.9);
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.8);
}
.demo-swiper-custom .swiper-pagination .swiper-pagination-bullet-active {
  z-index: 1;
  border: 1px solid #007aff;
  transform: scale(1.4);
}
.demo-swiper-cube {
  width: 80%;
  height: 70%;
  top: 15%;
}
.demo-swiper-slide-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.demo-swiper-slide-content span {
  position: relative;
  z-index: 1;
}
.demo-swiper-slide-content img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}
.demo-swiper-coverflow {
  height: 60%;
  top: 20%;
}
.demo-swiper-coverflow::part(slide) {
  width: 65%;
}
.demo-swiper-cube::part(slide),
.demo-swiper-coverflow::part(slide) {
  background-size: cover;
  color: #fff;
  -webkit-backface-visibility: hidden;
}
.demo-swiper-fade swiper-slide {
  background-size: cover;
  background-position: center;
}
.demo-swiper-gallery-top {
  height: 70%;
}
.demo-swiper-gallery-thumbs {
  margin-top: 10px;
  height: 20%;
  height: -webkit-calc(30% - 20px);
  height: -moz-calc(30% - 20px);
  height: -ms-calc(30% - 20px);
  height: calc(30% - 20px);
}
.demo-swiper-gallery-thumbs .swiper-slide-pic {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  transition: 300ms;
}
.demo-swiper-gallery-thumbs .swiper-slide-thumb-active .swiper-slide-pic {
  opacity: 1;
}
.demo-swiper-gallery-top swiper-slide,
.demo-swiper-gallery-thumbs .swiper-slide-pic {
  background-size: cover;
  background-position: center;
}
.demo-swiper-parallax {
  height: 100%;
}
.demo-swiper-parallax .swiper-parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}
.demo-swiper-parallax swiper-slide {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
  color: #fff;
}
.demo-swiper-parallax .swiper-slide-title {
  font-size: 41px;
  font-weight: 300;
}
.demo-swiper-parallax .swiper-slide-subtitle {
  font-size: 21px;
}
.demo-swiper-parallax .swiper-slide-text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}
.demo-swiper-lazy {
  height: 100%;
}
.demo-swiper-lazy swiper-slide {
  position: relative;
}
.demo-swiper-lazy swiper-slide img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Icons Demo */
code {
  background: #f3f3f3;
  padding: 5px;
  font-size: 12px;
}
.dark code {
  background: #000;
}
.demo-icon {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #333;
}
.dark .demo-icon {
  color: #fff;
}
.demo-icon .demo-icon-name {
  margin-top: 5px;
  font-size: 11px;
  color: #666;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dark .demo-icon .demo-icon-name {
  color: #aaa;
}

/* Demo Color Themes */
.demo-theme-picker {
  cursor: pointer;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  position: relative;
}
.demo-theme-picker .checkbox {
  position: absolute;
  left: 10px;
  bottom: 10px;
}
.demo-color-picker-button {
  text-transform: capitalize;
}
.demo-bars-picker {
  height: 200px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: var(--f7-page-bg-color);
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.demo-bars-picker .checkbox {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.demo-bars-picker .demo-navbar {
  position: absolute;
  left: 0;
  width: 100%;
  height: 30px;
  top: 0;
  border-bottom: 1px solid transparent;
}
.demo-bars-picker .demo-navbar:before {
  content: '';
  position: absolute;
  left: 10px;
  width: 20px;
  height: 10px;
  top: 50%;
  margin-top: -5px;
}
.demo-bars-picker .demo-navbar:after {
  content: '';
  position: absolute;
  right: 10px;
  width: 20px;
  height: 10px;
  top: 50%;
  margin-top: -5px;
}
.demo-bars-picker-empty .demo-navbar {
  background: #f7f7f8;
  border-color: rgba(0, 0, 0, 0.1);
}
.dark .demo-bars-picker-empty .demo-navbar {
  background: #1b1b1b;
  border-color: #282829;
}
.demo-bars-picker-empty .demo-navbar:before,
.demo-bars-picker-empty .demo-navbar:after {
  background: var(--f7-theme-color);
}
.demo-bars-picker-fill .demo-navbar {
  background: var(--f7-theme-color);
}
.demo-bars-picker-fill .demo-navbar:before,
.demo-bars-picker-fill .demo-navbar:after {
  background: #fff;
}

/* Demo Expandable Cards */
@media (min-width: 768px) {
  .demo-expandable-cards {
    display: flex;
    flex-wrap: wrap;
  }
  .demo-expandable-cards .card {
    flex-shrink: 10;
    min-width: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .demo-expandable-cards .card {
    width: calc((100% - var(--f7-card-expandable-margin-horizontal) * 3) / 2);
  }
  .demo-expandable-cards .card:nth-child(2),
  .demo-expandable-cards .card:nth-child(4) {
    margin-left: 0;
  }
  .demo-expandable-cards .card:nth-child(n + 3) {
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .demo-expandable-cards .card {
    width: calc((100% - var(--f7-card-expandable-margin-horizontal) * 5) / 4);
  }
  .demo-expandable-cards .card:nth-child(n + 2) {
    margin-left: 0;
  }
}
.view-master-detail .navbar-master-detail-root .link.back,
.view-master-detail .page-master-detail-root .navbar .link.back {
  display: none;
}

/* Demo Popup & Sheet Swipe To Close */
.demo-popup-swipe-handler {
  --f7-popup-border-radius: 15px 15px 0 0;
  --f7-popup-tablet-border-radius: 15px;
  --f7-block-strong-border-color: transparent;
  height: calc(100% - var(--f7-navbar-height) - var(--f7-safe-area-top));
  top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
}
.demo-popup-swipe-handler .swipe-handler {
  background: var(--f7-page-bg-color);
}
.demo-popup-swipe-handler .page-content {
  padding-top: 16px;
}
.demo-sheet-swipe-to-close,
.demo-sheet-swipe-to-step {
  --f7-sheet-border-color: transparent;
  border-radius: 15px 15px 0 0;
  overflow: hidden;
}
.demo-sheet-swipe-to-close .swipe-handler,
.demo-sheet-swipe-to-step .swipe-handler {
  background: none;
}
.swipe-handler {
  height: 16px;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  background: #fff;
  cursor: pointer;
  z-index: 10;
}
.swipe-handler:after {
  content: '';
  width: 36px;
  height: 6px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -18px;
  margin-top: -3px;
  border-radius: 3px;
  background: #666;
}

.area-chart {
  max-width: 768px;
  margin: 0 auto;
}
.area-chart svg {
  max-height: 320px;
}
.pie-chart svg {
  max-width: 320px;
}
.example-preview .view-main .navbars .navbar:first-child .link.back,
.example-preview .view-main .page:first-child .navbar .link.back {
  min-width: 0 !important;
  width: 0 !important;
  padding: 0 !important;
  opacity: 0 !important;
  margin-right: 8px !important;
  pointer-events: none;
}
.example-preview .example-hidden {
  display: none !important;
}

/* Demo Chips */
.block .chip {
  margin: 2px 4px 2px 0;
}

/* Calendar Page */
#calendar,
#calendar-events {
  height: 50%;
  box-sizing: border-box;
}

#calendar .calendar {
  height: 100%;
}

#calendar-events ul {
  height: 100%;
  overflow: auto;
}

#calendar-events .event-color {
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 100%;
}

@media (orientation: landscape) {
  #calendar {
    float: left;
  }

  #calendar,
  #calendar-events {
    height: 100%;
  }

  #calendar,
  #calendar-events {
    width: 50%;
  }

  #calendar-events {
    margin-left: 50% !important;
    border-left: 1px solid #eee;
  }

  .dark #calendar-events {
    border-left-color: #282828;
  }
}
